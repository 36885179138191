import { CardAutoMoveBaseController } from '@/core/card-auto-move.base.controller';
import { Card, CardOwner, Hint } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { coreBus } from '@/core/core-bus';
import { judge } from '@/games/scorpion/judge';

export class AutoMoveController extends CardAutoMoveBaseController {
    protected doBestMove(card: Card): void {
        if (card.owner != CardOwner.tableau) {
            return;
        }

        // safe guard check if card is face up
        if (!card.isFaceUp) {
            return;
        }

        // try to meld card
        const tableauTops = cardsQuery.getTopTableauCards();
        for (let i = 0; i < tableauTops.length; i++) {
            const c = tableauTops[i];
            if (judge.canPutCardOnTopOf(card, c)) {
                coreBus.meldCardCmd$.next({
                    card,
                    destCard: c,
                });
                return;
            }
        }

        // try to move king card to empty tableau
        for (let i = 1; i <= 7; i++) {
            if (judge.canMoveToEmptyTableauFrame(card, i)) {
                coreBus.moveCardToEmptyTableauCmd$.next({
                    card,
                    tableauIndex: i,
                });
                return;
            }
        }

        // if we got here we could find any auto move
        // we want to shake the card
        coreBus.shakeCardCmd$.next({
            card,
        });
    }

    protected isAutoFinishPossible(): boolean {
        return false;
    }

    protected doAutoFinish(done: () => void): void {
        done();
    }

    protected generateHints(): Hint[] {
        const tableauTops = cardsQuery.getTopTableauCards();
        const tabCards = cardsQuery.getAllByOwner(CardOwner.tableau);
        return tabCards
            .filter((c) => c.isFaceUp)
            .flatMap((c) => {
                return tableauTops
                    .filter((tc) => tc.ownerIndex != c.ownerIndex && judge.canPutCardOnTopOf(c, tc))
                    .map(
                        (tc) =>
                            ({
                                card1Id: c.id,
                                card2Id: tc.id,
                                priority: 0,
                            } as Hint)
                    );
            });
    }
}
