import { Card, CardOwner } from '@/core/models';
import { judge } from '@/games/scorpion/judge';
import { coreBus } from '@/core/core-bus';
import { cardsQuery } from '@/state/cards/cards.query';
import { moveHistory } from '@/core/move-history';
import { cardsService } from '@/state/cards/cards.service';
import { gameService } from '@/state/game/game.service';
import { orderBy } from 'lodash';
import { coreUtil } from '@/core/core-util';
import { CardMoveBaseController } from '@/core/card-move-base.controller';
import { cardSound } from '@/core/sound';

export class MoveController extends CardMoveBaseController {
    constructor() {
        super({
            enableTableauAutoAdjust: true,
            meldCard: {
                validate: (cmd) => judge.canPutCardOnTopOf(cmd.card, cmd.destCard),
            },
            moveCardToEmptyTableauCmd: {
                validate: (cmd) => judge.canMoveToEmptyTableauFrame(cmd.card, cmd.tableauIndex),
            },
        });
        this.subscribeTo(coreBus.stockClickEvent$, () => {
            this.dealStockCards();
            coreBus.gameMoveCompletedEvent$.next();
        });
        this.subscribeTo(coreBus.gameMoveCompletedEvent$, async (ev) => {
            // we don't want to do anything if event come from this process
            // so we not getting into infinite loop
            if (ev && ev.name == 'after-sequence') {
                return;
            }

            await coreUtil.delay(400);

            this.checkIfSequenceCompleted();

            await coreUtil.delay(500);

            coreBus.gameMoveCompletedEvent$.next({
                name: 'after-sequence',
            });
        });
    }

    private dealStockCards() {
        if (!this.canMakeMove()) {
            return;
        }

        // we should have 3 cards left to deal
        const cards = orderBy(cardsQuery.getAllByOwner(CardOwner.stock), (c) => c.order, 'desc');

        if (cards.length != 3) {
            return;
        }

        moveHistory.startMove();

        const updates = cards.map((c, i) => {
            const top = cardsQuery.getTopByOwnerAndIndex(CardOwner.tableau, i + 1);
            const order = top ? top.order + 1 : 1;
            moveHistory.addState(c);
            return {
                ...c,
                owner: CardOwner.tableau,
                ownerIndex: i + 1,
                order,
                dragEnabled: true,
                isFaceUp: true,
            };
        });
        cardsService.upsertMany(updates);

        updates.forEach((c) => {
            coreBus.cardMoveCmd$.next({
                duration: 300,
                cardId: c.id,
            });
        });

        gameService.increaseMoveCounter();
        moveHistory.endMove();
        cardSound.playMove();
    }

    private checkIfSequenceCompleted() {
        [1, 2, 3, 4, 5, 6, 7].forEach((i) => {
            this.checkIfSequenceCompletedForSpecificTableau(i);
        });
    }

    private checkIfSequenceCompletedForSpecificTableau(index: number) {
        const cards = cardsQuery.getOrderedByOwnerAndIndex(CardOwner.tableau, index);

        if (cards.length < 13) {
            return;
        }

        let seq: Card[] = [];
        for (let i = 0; i < cards.length; i++) {
            const card = cards[i];
            if (card.rank == 13) {
                seq = [card];
            } else {
                if (
                    seq.length > 0 &&
                    card.rank == seq[seq.length - 1].rank - 1 &&
                    card.suit == seq[seq.length - 1].suit
                ) {
                    seq.push(card);
                    if (seq.length == 13) {
                        break;
                    }
                } else {
                    seq = [];
                }
            }
        }

        if (seq.length == 13) {
            moveHistory.startMove();

            const indexFound = coreUtil.getFoundation4IndexForSuit(seq[0].suit);
            const updates = seq.map((c, i) => {
                moveHistory.addState(c);
                return {
                    ...c,
                    order: seq.length - i,
                    owner: CardOwner.foundation,
                    ownerIndex: indexFound,
                    dragEnabled: false,
                };
            });
            cardsService.upsertMany(updates);
            updates.forEach((c) => {
                coreBus.cardMoveCmd$.next({
                    duration: 300,
                    cardId: c.id,
                });
            });

            // we need to update the cards that left to the right order
            const updatesLeft = cardsQuery
                .getOrderedByOwnerAndIndex(CardOwner.tableau, index)
                .map((c, i) => {
                    moveHistory.addState(c);
                    return {
                        ...c,
                        order: i + 1,
                    };
                });
            cardsService.upsertMany(updatesLeft);
            updatesLeft.forEach((c) => {
                moveHistory.addState(c);
                coreBus.cardMoveCmd$.next({
                    duration: 300,
                    cardId: c.id,
                });
            });

            cardsService.updateTableauCount();
            this.moveTableauCards(
                updates.map((c) => c.id),
                index
            );

            gameService.increaseMoveCounter();
            moveHistory.endMove();
            cardSound.playScore();
        }
    }
}
