import { CardDealerBaseController } from '@/core/card-dealer.base.controller';
import { cardsQuery } from '@/state/cards/cards.query';
import { CardOwner } from '@/core/models';
import { cardsService } from '@/state/cards/cards.service';
import { coreBus } from '@/core/core-bus';

export class DealerController extends CardDealerBaseController {
    constructor() {
        super(30, 49);
    }

    protected dealCard(i: number): void {
        const card = cardsQuery.getTopByOwner(CardOwner.stock);
        if (!card) {
            throw new Error('no card in stock');
        }

        // tableau 1
        if (i >= 1 && i <= 7) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 1,
                order: i,
                isFaceUp: i >= 4,
                dragEnabled: i >= 4,
            });
        }

        // tableau 2
        if (i >= 8 && i <= 14) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 2,
                order: i - 7,
                isFaceUp: i >= 11,
                dragEnabled: i >= 11,
            });
        }

        // tableau 3
        if (i >= 15 && i <= 21) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 3,
                order: i - 14,
                isFaceUp: i >= 18,
                dragEnabled: i >= 18,
            });
        }

        // tableau 4
        if (i >= 22 && i <= 28) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 4,
                order: i - 21,
                isFaceUp: i >= 25,
                dragEnabled: i >= 25,
            });
        }

        // tableau 5
        if (i >= 29 && i <= 35) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 5,
                order: i - 28,
                isFaceUp: true,
                dragEnabled: true,
            });
        }

        // tableau 6
        if (i >= 36 && i <= 42) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 6,
                order: i - 35,
                isFaceUp: true,
                dragEnabled: true,
            });
        }

        // tableau 7
        if (i >= 43 && i <= 49) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 7,
                order: i - 42,
                isFaceUp: true,
                dragEnabled: true,
            });
        }

        coreBus.cardMoveCmd$.next({
            duration: 0,
            cardId: card.id,
        });
    }
}
