<template>
    <div class="frame noselect" :class="{ highlight }">
        <div class="suit" :class="suit"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        suit: { type: String, required: true },
        highlight: { type: Boolean },
    },
});
</script>

<style scoped>
.frame {
    border: #777 1px solid;
    position: absolute;
    background-color: rgba(50, 50, 50, 0.5);
    pointer-events: none;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
}
.frame.highlight {
    -webkit-box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 1);
}
.frame .suit {
    background-position: center;
    -webkit-background-size: 40% 40%;
    -moz-background-size: 40%;
    -o-background-size: 40%;
    background-size: 40%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.6;
}
.frame .suit.heart {
    background-image: url('/img/heart.png');
}
.frame .suit.spade {
    background-image: url('/img/spade.png');
}
.frame .suit.diamond {
    background-image: url('/img/diamond.png');
}
.frame .suit.club {
    background-image: url('/img/club.png');
}
@media only screen and (max-width: 600px) {
    .frame.highlight {
        -webkit-box-shadow: inset 0px 0px 5px 2px rgba(255, 255, 255, 1);
        -moz-box-shadow: inset 0px 0px 5px 2px rgba(255, 255, 255, 1);
        box-shadow: inset 0px 0px 5px 2px rgba(255, 255, 255, 1);
    }
}
</style>
