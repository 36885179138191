import { BaseGameController } from '@/core/base-game.controller';
import { Card, CardOwner, GameStatus, Suit } from '@/core/models';
import { range } from 'lodash';
import { cardsService } from '@/state/cards/cards.service';
import { moveHistory } from '@/core/move-history';
import { cardsQuery } from '@/state/cards/cards.query';
import { coreBus } from '@/core/core-bus';
import { gameQuery } from '@/state/game/game.query';

export class GameController extends BaseGameController {
    constructor() {
        super();
        this.initStage();
        this.subscribeTo(gameQuery.gameStatus$, (status) => {
            if (status == GameStatus.dealCompleted) {
                if (process.env.NODE_ENV === 'development') {
                    setTimeout(() => this.setCustomBoard(), 2000);
                }
            }
        });
    }

    private initStage() {
        const suits = [Suit.Diamond, Suit.Spade, Suit.Club, Suit.Heart];
        const cards = suits.flatMap((s) => {
            return range(1, 14).map(
                (r) =>
                    ({
                        id: `${r}${s}`,
                        name: `${r}${s}`,
                        suit: s,
                        rank: r,
                        order: 0,
                        dragEnabled: false,
                        owner: CardOwner.stock,
                    } as Card)
            );
        });
        cardsService.init(cards);
    }

    protected initGame(): void {
        cardsService.resetAllCards(CardOwner.stock);
        moveHistory.reset();
    }

    protected isGameCompleted(): boolean {
        return cardsQuery.getAllByOwner(CardOwner.foundation).length == 52;
    }

    private setCustomBoard() {
        const board: { owner: CardOwner; index: number; cards: string[] }[] = [
            {
                owner: CardOwner.tableau,
                index: 1,
                cards: ['13H', '12H', '11H', '10H', '9H', '8H', '7H'],
            },
            {
                owner: CardOwner.tableau,
                index: 2,
                cards: ['13C', '12C', '11C', '10C', '9C', '8C', '5D', '4D', '3D', '2D', '1D', '7C'],
            },
            {
                owner: CardOwner.tableau,
                index: 3,
                cards: ['13S', '12S', '11S', '10S', '9S', '8S', '7S'],
            },
            {
                owner: CardOwner.tableau,
                index: 4,
                cards: ['13D', '12D', '11D', '10D', '9D', '8D', '7D', '6D'],
            },
            {
                owner: CardOwner.tableau,
                index: 5,
                cards: ['6H', '5H', '4H', '3H', '2H', '1H'],
            },
            {
                owner: CardOwner.tableau,
                index: 6,
                cards: ['6C', '5C', '4C', '3C', '2C', '1C'],
            },
            {
                owner: CardOwner.tableau,
                index: 7,
                cards: ['6S', '5S', '4S', '3S', '2S', '1S'],
            },
        ];

        const avails = cardsQuery.getAll().filter((card) => {
            for (let i = 0; i < board.length; i++) {
                if (board[i].cards.indexOf(card.id) >= 0) {
                    return false;
                }
            }
            return true;
        });

        cardsService.update(null, {
            owner: CardOwner.none,
        });

        const updates = board.flatMap((item) => {
            return item.cards.flatMap((id, i) => {
                const card = id != '' ? cardsQuery.getEntity(id) : avails.pop();
                if (card) {
                    return [
                        {
                            // eslint-disable-next-line
                            ...card!,
                            isFaceUp: true,
                            dragEnabled: true,
                            owner: item.owner,
                            ownerIndex: item.index,
                            order: i + 1,
                        },
                    ];
                } else {
                    return [];
                }
            });
        });

        cardsService.upsertMany(updates);
        cardsService.updateTableauCount();
        cardsQuery.getAll().forEach((c) => {
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 0,
            });
        });
    }
}
