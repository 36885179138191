import { ControllersBase } from '@/core/controllers.base';
import { GameController } from '@/games/scorpion/controllers/game.controller';
import { DealerController } from '@/games/scorpion/controllers/dealer.controller';
import { DragController } from '@/games/scorpion/controllers/drag.controller';
import { MoveController } from '@/games/scorpion/controllers/move.controller';
import { AutoMoveController } from '@/games/scorpion/controllers/auto-move.controller';

export class Controllers extends ControllersBase {
    init(): void {
        this.add(new GameController());
        this.add(new DealerController());
        this.add(new DragController());
        this.add(new MoveController());
        this.add(new AutoMoveController());
    }
}

export const controllers = new Controllers();
