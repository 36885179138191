import { Card, CardOwner } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';

export class Judge {
    canPutCardOnTopOf(card: Card, dest: Card) {
        if (dest.owner != CardOwner.tableau) {
            return false;
        }
        if (card.rank != dest.rank - 1) {
            return false;
        }
        if (card.ownerIndex == dest.ownerIndex) {
            return false;
        }
        return card.suit == dest.suit;
    }

    canMoveToEmptyTableauFrame(card: Card, ownerIndex: number) {
        if (card.rank != 13) {
            return false;
        }
        const top = cardsQuery.getTopByOwnerAndIndex(CardOwner.tableau, ownerIndex);
        return !top;
    }
}

export const judge = new Judge();
