import { Controller } from '@/core/controller';

export abstract class ControllersBase {
    private controllers: Controller[] = [];

    abstract init(): void;

    protected add(controller: Controller) {
        this.controllers.push(controller);
    }

    destroy() {
        this.controllers.forEach((c) => c.destroy());
    }
}
